import {
  Checklist1 as Checklist,
  Clipboard,
  Shield2,
  Users,
  Store,
} from '@admin-portal-shared-components/icons';
import { List2 } from '@hexa-ui/icons';
import {
  useAnalyticsService,
  useGetEnvPropPerCluster,
  useHasPermission,
  useSidebar,
  useGetCluster,
} from 'admin-portal-shared-services';
import React, { useMemo } from 'react';
import { Router } from '../Router';

export const menuIcons = {
  clipboard: Clipboard,
  checklist: Checklist,
  users: Users,
  shield: Shield2,
  store: Store,
  list: () => <List2 fr={undefined} />,
};

export const AppBarConfig = (): JSX.Element => {
  const analyticsService = useAnalyticsService();
  const segmentKey = String(useGetEnvPropPerCluster('segmentKey'));
  const hasUserReadPermission = useHasPermission('IdentityFE.User.Read');
  const hasUserWritePermission = useHasPermission(['IdentityFE.User.Write', 'Identity.User.Write']);
  const hasPOCReadPermission = useHasPermission('IdentityFE.POC.Read');
  const hasPermissionToBatchActions = useHasPermission('IdentityFE.BatchAction.Write');
  const hasPermissionWrite = useHasPermission('IdentityFE.Permission.Write');
  const hasPermissionRead = useHasPermission('IdentityFE.Permission.Read');
  const hasLinkManagementWritePermission = useHasPermission('IdentityFE.LinkManagement.Execute');
  const cluster = useGetCluster();
  const isGlobal = cluster === 'Global_US';
  const hasPermissionsToViewAffiliations = useHasPermission('IdentityFE.Affiliations.Read');

  analyticsService.load(segmentKey);

  const memoListMenuWithAccess = useMemo(
    () =>
      [
        {
          id: '1',
          title: 'Accounts',
          icon: menuIcons.clipboard,
          path: '/accounts',
          hasAccess: hasPOCReadPermission,
        },
        {
          id: '2',
          title: 'Users',
          icon: menuIcons.users,
          path: '/users',
          hasAccess: hasUserReadPermission || hasUserWritePermission,
        },
        {
          id: '3',
          title: 'Bulk actions',
          icon: menuIcons.checklist,
          path: '/users/bulk-actions',
          hasAccess: hasPermissionToBatchActions,
        },
        {
          id: '4',
          title: 'Roles & Permissions',
          icon: menuIcons.shield,
          path: '/permissions',
          hasAccess: hasPermissionWrite || hasPermissionRead,
        },
        {
          id: '5',
          title: 'Accounts linking',
          icon: menuIcons.store,
          path: '/accounts/link-accounts',
          hasAccess: hasLinkManagementWritePermission && isGlobal,
        },
        {
          id: '6',
          title: 'Affiliations',
          icon: menuIcons.list,
          path: '/permissions/affiliations',
          hasAccess: hasPermissionsToViewAffiliations,
        },
      ].filter((listItem) => listItem.hasAccess),
    [
      hasLinkManagementWritePermission,
      hasPOCReadPermission,
      hasPermissionRead,
      hasPermissionToBatchActions,
      hasPermissionWrite,
      hasUserReadPermission,
      hasUserWritePermission,
      isGlobal,
      hasPermissionsToViewAffiliations,
    ]
  );

  const memoSidebarParams = useMemo(
    () => ({
      items: memoListMenuWithAccess,
      utils: [],
    }),
    [memoListMenuWithAccess]
  );

  useSidebar(memoSidebarParams);

  return <Router />;
};
